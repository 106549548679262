h1,td,th,tspan,p{
  font-family: 'Poppins', sans-serif;
}

header{
  text-align: center;
}

td {
  padding-bottom:5px;
  border-bottom: 2px solid #000;
}
.winner, .tie, .loser{
  color:#111;
  padding:10px;
}
  .winner{
    background-color: #49E83E;
  }
  .tie{
    background-color: #FFD432;
  }
  .loser{
    background-color:#E84B30;
  }
  .standings td{
    padding: 0 20px;
  }

.breakdown .outcome,
.breakdown th{
  position:relative;
  left: 210px;
}

  .standings td.name-cell{
    min-width:200px;
  }
  .chips-table td{
    vertical-align: top;
    padding-right:30px;
    min-width:200px;
  }
  .member-cell,.gameweek-cell{
    min-width:200px;
    position: absolute;
    left: 0;
    background: white;
    z-index:10;
  }
  .member-cell{
    padding: 10px;
  }
  .gameweek-cell{
    padding-left:10px;
  }

.live-summary .has-played-true{
  background-color: #49E83E;
}

.live-summary .has-played-false{
    background-color:#E84B30;
}

.live-summary .has-played-no-show{
    background-color: #FFD432;
}
.player-table .name-cell,
.live-summary .name-cell{
  font-weight:bold;
  min-width:100px;
}
.live-summary td{
  vertical-align: top;
}

.playerTable{
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.player{
  border: 1px solid #797b7d;
  margin:2px;
}

.player-points,
.player-name{
  padding:5px;
}

.player-points{
  background-color: #000;
  color: #fff;
  display: inline-block;
}
.isCaptain,
.isViceCaptain{
  padding: 2px 5px;
  border-radius:100%;
  background-color: #000;
  color: #fff;
  margin-right: 5px;
}
.live-summary-title{
  text-align:center;
  padding:40px 0;
}

@media (min-width: 1000px) {
  .player-leaderboard,
  .live-summary{
    width:1000px;
    margin: 0 auto;
  }
}

@media (max-width: 999px) {
  .player-leaderboard,
  .live-summary {
    width: 100%;
    overflow-x: auto;
  }
}